import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="Message Sent" />
    <section className={`fix-header`}>
      <div className={`content--md-center`}>
        <h1>Message Sent</h1>
        <p>Alright! I've successfully received your message and will be reading it soon. Thank you for reaching out.</p>
        <Link to="/"><button>Back Home</button></Link>
      </div>
    </section>
  </>
)

export default NotFoundPage